<template>
	<div class="diy_details diy_div_room_management">
		<div class="warp">
			<div class="container">
				<div class="row">
				<div class="col-12 col-md-6" v-if="$check_field('get','room_number')">
					<div class="view">
						<div class="diy_title">
							<span>房号</span>
						</div>
						<div class="diy_field diy_text">
							<span>
								{{ obj["room_number"] }}
							</span>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6" v-if="$check_field('get','type')">
					<div class="view">
						<div class="diy_title">
							<span>类型</span>
						</div>
						<div class="diy_field diy_text">
							<span>
								{{ obj["type"] }}
							</span>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6" v-if="$check_field('get','picture')">
					<div class="view">
						<div class="diy_title">
							<span>图片</span>
						</div>
						<div class="diy_field diy_img">
							<img :src="$fullUrl(obj['picture'])" />
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6" v-if="$check_field('get','use_status')">
					<div class="view">
						<div class="diy_title">
							<span>使用状态</span>
						</div>
						<div class="diy_field diy_text">
							<span>
								{{ obj["use_status"] }}
							</span>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6" v-if="$check_field('get','remarks')">
					<div class="view">
						<div class="diy_title">
							<span>备注</span>
						</div>
						<div class="diy_field diy_desc">
							<span>
								{{ obj["remarks"] }}
							</span>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6" v-if="$check_field('get','price')">
					<div class="view">
						<div class="diy_title">
							<span>价格</span>
						</div>
						<div class="diy_field diy_number">
							<span>
								{{ obj["price"] }}
							</span>
						</div>
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixin from "@/mixins/component.js";

	export default {
		mixins: [mixin],
		components: {

		},
		props: {
			obj: {
				type: Object,
				default: function() {
					return {};
				},
			}
		},
		data() {
			return {

			};
		},
		methods: {

		},
	};
</script>

<style>

</style>
