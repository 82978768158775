var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_details diy_div_room_management"},[_c('div',{staticClass:"warp"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(_vm.$check_field('get','room_number'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(0),_c('div',{staticClass:"diy_field diy_text"},[_c('span',[_vm._v(" "+_vm._s(_vm.obj["room_number"])+" ")])])])]):_vm._e(),(_vm.$check_field('get','type'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(1),_c('div',{staticClass:"diy_field diy_text"},[_c('span',[_vm._v(" "+_vm._s(_vm.obj["type"])+" ")])])])]):_vm._e(),(_vm.$check_field('get','picture'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(2),_c('div',{staticClass:"diy_field diy_img"},[_c('img',{attrs:{"src":_vm.$fullUrl(_vm.obj['picture'])}})])])]):_vm._e(),(_vm.$check_field('get','use_status'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(3),_c('div',{staticClass:"diy_field diy_text"},[_c('span',[_vm._v(" "+_vm._s(_vm.obj["use_status"])+" ")])])])]):_vm._e(),(_vm.$check_field('get','remarks'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(4),_c('div',{staticClass:"diy_field diy_desc"},[_c('span',[_vm._v(" "+_vm._s(_vm.obj["remarks"])+" ")])])])]):_vm._e(),(_vm.$check_field('get','price'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(5),_c('div',{staticClass:"diy_field diy_number"},[_c('span',[_vm._v(" "+_vm._s(_vm.obj["price"])+" ")])])])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("房号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("类型")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("图片")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("使用状态")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("备注")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("价格")])])
}]

export { render, staticRenderFns }